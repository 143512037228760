import { usePageContent } from "@appiodev/xcore-client/xcore-ui";
import { FC } from "react";
import { Product, ShortVideo, ShortVideoValues } from "xcore/types";

import ProductAccessories from "./ProductAccessories";
import ProductColors from "./ProductColors";
import ProductDownloads from "./ProductDownloads";
import ProductFooter from "./ProductFooter";
import ProductIntro from "./ProductIntro";
import ProductNews from "./ProductNews";
import ProductPictures from "./ProductPictures";
import ProductSpecification from "./ProductSpecification";
import ProductVideo from "./ProductVideo";
import { resolveLocaleField } from "utils/useLocalizedRelation";
import ProductKeyFeatures from "./ProductKeyFeatures";
import { Relation } from "@appiodev/xcore-core";
import { useRouter } from "next/router";
import { capitalize } from "utils/capitalize";

const ProductDetail: FC = () => {
  const [product, { value, cms }] = usePageContent<Product>();
  const { locale } = useRouter();
  const isCurrentLocalePublished = Object.keys(product.publishedAt).some(l => l === locale);

  // if current locale is published without any key features, do not show fallback locale key features. Key features will be shown only if specified in current locale or locale fallback logic will be used if current locale is unpublished
  const keyFeatures = `keyFeatures${capitalize(locale ?? "En")}` in value(product.values) || !isCurrentLocalePublished
    ? resolveLocaleField(
      "keyFeatures",
      value(product.values),
      [] as Relation<ShortVideo<keyof ShortVideoValues>>[],
      cms.locales.content
    )
    : null;

  return (
    <>
      <ProductIntro />
      {keyFeatures && <ProductKeyFeatures keyFeatures={keyFeatures} />}
      {product.values.images && <ProductPictures />}
      {product.values.videos && <ProductVideo />}
      <ProductDownloads />
      {product.values.wheels && <ProductColors />}
      {product.values.accessories && <ProductAccessories />}
      {product.values.specification && <ProductSpecification />}
      {product.fetchedReverseRelations!.relatedNews.length > 0 && <ProductNews />}
      <ProductFooter />
    </>
  );
};

export default ProductDetail;
