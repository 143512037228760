import { InlineValue, useContent, usePageContent } from "@appiodev/xcore-client/xcore-ui";
import { Box, Collapse, Container, Link, Stack, Text } from "@xcorejs/ui";
import DropIcon from "components/icons/16/drop.svg";
import RobeProductDetailHeading from "design-system/robe/RobeProductDetailHeading";
import Accordion from "design-system/xcore/Accordion";
import AccordionHeader from "design-system/xcore/Accordion/AccordionHeader";
import AccordionItem from "design-system/xcore/Accordion/AccordionItem";
import AccordionPanel from "design-system/xcore/Accordion/AccordionPanel";
import { useAccordion } from "design-system/xcore/Accordion/data";
import { FC } from "react";
import { Product, ProductDetailPage } from "xcore/types";

import { datasheetUrl, useSection } from "./data";
import { useRouter } from "next/router";
import RobeRichtext from "components/RobeRichtext";

const ProductSpecification: FC = () => {
  const { locale } = useRouter();
  const [{ values, id }, { value, stringify }] = usePageContent<Product>();
  const [detailPage] = useContent<[ProductDetailPage]>();

  const [active, toggle] = useSection();
  const { accordion, expandAll, collapseAll } = useAccordion({
    multiple: true,
    expanded: values.specification.length ? [value(values.specification[0].type?.id).toString()] : []
  });

  return (
    <Box id={"#" + stringify(detailPage.values.anchorTechnicalSpecs)} backgroundColor="#dee1e5" borderX={{ _: 0, xxl: "3rem solid #fff" }}>
      <Box id={stringify(detailPage.values.anchorTechnicalSpecs)} transform={{ _: "translateY(-6rem)", md: "translateY(-13.9rem)" }} />
      <Container
        flexDirection="column"
        py={{ _: "1.5rem", sm: "8rem" }}
        my={{ _: "0", sm: "5rem" }}
      >
        <RobeProductDetailHeading mb={active ? "2rem" : 0} onClick={toggle} active={active}>
          <InlineValue value={detailPage.values.technicalSpec} />
        </RobeProductDetailHeading>

        <Collapse open={active}>
          <Box mb={{ _: "3rem", sm: "4rem" }}>
            <Stack justify="center" gap={{ _: "2rem", sm: "4rem" }}>
              {values.specification.length > 1 && (
                <Text variant="strong">
                  <Link onClick={() => expandAll()} variant="underline">
                    {stringify(detailPage.values.expandAll)}
                  </Link>
                </Text>
              )}
              <Text variant="strong">
                <Link
                  variant="underline"
                  target="_blank"
                  href={datasheetUrl(id, locale ?? "en")}
                >
                  {stringify(detailPage.values.downloadPdf)}
                </Link>
              </Text>
              {values.specification.length > 1 && (
                <Text variant="strong">
                  <Link onClick={() => collapseAll()} variant="underline">{stringify(detailPage.values.collapseAll)}</Link>
                </Text>
              )}
            </Stack>
          </Box>

          <Box width="85rem" maxWidth="100%" margin="auto">
            <Accordion {...accordion}>
              {values?.specification.map((s) => (
                <AccordionItem key={value(s).type!.id}>
                  <AccordionHeader
                    icon={<DropIcon />}
                    _expanded={{ _icon: { fill: "black" } }}
                    mt="1rem"
                    alignItems="center"
                    justifyContent="space-between"
                    withAnimatedIcon
                  >
                    <Text variant="strong" color="black">{stringify(s.type?.title)}</Text>
                  </AccordionHeader>

                  <AccordionPanel>
                    <Box bg="#fff" mt="0.2rem" p="2rem" pb="0">
                      <RobeRichtext
                        value={s.description}
                        _paragraph={{ fontSize: "1.4rem", lineHeight: "2.8rem" }}
                        _strong={{ fontWeight: 600 }}
                        _liClassName="product-specs-li"
                      />
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </Box>
        </Collapse>
      </Container>
    </Box>
  );
};

export default ProductSpecification;
