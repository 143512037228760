import { InlineValue, useContent, usePageContent } from "@appiodev/xcore-client/xcore-ui";
import { Relation } from "@appiodev/xcore-core";
import { Box, Flex, Icon, Link } from "@xcorejs/ui";
import ArrowIcon from "components/icons/32/arrow.svg";
import RobeRichtext from "components/RobeRichtext";
import VideoCarousel from "components/VideoCarousel";
import RobeProductDetailHeading from "design-system/robe/RobeProductDetailHeading";
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";
import styled from "styled-components";
import { ProductDetailPage, ShortVideo, ShortVideoValues } from "xcore/types";
import { ShortVideoRestructured } from "./ShortVideo";

type KeyFeaturesProps = {
  keyFeatures: Relation<ShortVideo<keyof ShortVideoValues>>[];
};

const SCREEN_HIGHER_THAN_WIDER_PX = 128; // how many pixels should be screen higher than wider before switching from portrait mode to landscape mode

const ProductKeyFeatures: FC<KeyFeaturesProps> = ({ keyFeatures }) => {
  const [detailPage] = useContent<[ProductDetailPage]>();
  const [, { value, stringify, localize }] = usePageContent();
  const [currentVideo, setCurrentVideo] = useState<number>(0);
  const [isLandscapeMode, setIsLandscapeMode] = useState<boolean | null>(null);

  const [scrollbarWidth, setScrollbarWidth] = useState<number>(0); // 0 = scrollbar is hidden
  // const [loadedVideosIds, setLoadedVideosIds] = useState<Array<number>>([]); // *thumbnail rendering feature*

  const [slidesContainerPadding, setSlidesContainerPadding] = useState<number>(0);
  const slidesContainerRef = useRef<HTMLDivElement>(null);

  const carouselRef = useRef<HTMLDivElement>(null);

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => handleArrowSlideChange(-1),
    onSwipedLeft: () => handleArrowSlideChange(1)
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      // square format videos only fit on the screen until the point where screen height is slightly greater than screen width (more or less the 128px)
      setIsLandscapeMode(window.innerHeight - window.innerWidth < SCREEN_HIGHER_THAN_WIDER_PX);
    }
  }, []);
  // requested priority of video ratio for landscape mode is 16:9 -> 1:1 -> 9:16 and for portrait mode 9:16 -> 1:1 -> 16:9. This func chooses the correct ratio based on the priority
  // returns 1 for 16:9, 2 for 1:1 and 3 for 9:16
  const pickCorrectVideoRatio = (video: Relation<ShortVideo<keyof ShortVideoValues>>) => {
    if (isLandscapeMode) {
      return video.values?.shortVideo1
        ? 1
        : video.values?.shortVideo2
          ? 2
          : video.values?.shortVideo3
            ? 3
            : 1;
    }

    return video.values?.shortVideo3
      ? 3
      : video.values?.shortVideo2
        ? 2
        : video.values?.shortVideo1
          ? 1
          : 1;
  };

  const restructureToShortVideoType = (video: Relation<ShortVideo<keyof ShortVideoValues>>): ShortVideoRestructured => {
    const videoNumber = pickCorrectVideoRatio(video);

    return {
      ...video,
      values: {
        description: video.values?.description,
        internalDes: video.values?.internalDes,
        title: video.values?.title,
        videoThumbnail: video.values?.[`videoThumbnail${videoNumber}`],
        shortVideo: video.values?.[`shortVideo${videoNumber}`],
        autoPlay: video.values?.[`autoPlay${videoNumber}`],
        loop: video.values?.[`loop${videoNumber}`],
        mute: video.values?.[`mute${videoNumber}`],
        noControl: video.values?.[`noControl${videoNumber}`],
        darkBackground: video.values?.[`darkBackground${videoNumber}`],
        ratio: videoNumber === 1 ? 9 / 16 : videoNumber === 2 ? 1 / 1 : 16 / 9 // XcoreJS/UI's <AspectRatio /> has switched calculation for width and height, so for 16:9 format (videoNumber 1) value of 9 / 16 is assigned and so on
      }
    };
  };

  const videosToShow = useMemo(() => {
    if (isLandscapeMode !== null) {
      return keyFeatures.map(kf => restructureToShortVideoType(kf)).filter(kf => kf.values.shortVideo)
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [keyFeatures, isLandscapeMode]);

  const [screenWidth, setScreenWidth] = useState(0);
  const [currentFeatureItemWidth, setCurrentFeatureItemWidth] = useState(0);
  const [totalPreviousFeatureItemsWidth, setTotalPreviousFeatureItemsWidth] = useState(0);

  // compute slidesContainer padding in order to center correctly items in the menu
  useEffect(() => {
    if (slidesContainerRef.current) {
      const leftPadding = slidesContainerRef.current.getBoundingClientRect().left;
      const rightPadding = screenWidth - slidesContainerRef.current.getBoundingClientRect().right;
      setSlidesContainerPadding(Math.abs(leftPadding + rightPadding));
    }
  }, [screenWidth, slidesContainerRef]);

  // compute all previous items widths
  useEffect(() => {
    let totalItemsWidth = 0;
    for (let i = 0; i < currentVideo; i++) {
      const previousFeatureItem = document.getElementById(`feature-item-${i}`);
      totalItemsWidth += previousFeatureItem?.clientWidth ?? 0;
    }

    setTotalPreviousFeatureItemsWidth(totalItemsWidth);
  }, [currentVideo, videosToShow?.length]);

  const updateScreenWidth = useCallback(() => {
    setScreenWidth(window?.innerWidth);
    setScrollbarWidth(window.innerWidth - document.documentElement.clientWidth);
    if (typeof window !== "undefined" && window.innerHeight - window.innerWidth < SCREEN_HIGHER_THAN_WIDER_PX) {
      setIsLandscapeMode(true);
    } else setIsLandscapeMode(false);
  }, []);

  useEffect(updateScreenWidth, [updateScreenWidth]);
  useEffect(() => {
    window.addEventListener("resize", updateScreenWidth);
    return () => window.removeEventListener("resize", updateScreenWidth);
  });

  if (!videosToShow?.length) return null;

  const handleArrowSlideChange = (linkIndex: 1 | -1) => {
    if (linkIndex === 1 && currentVideo === videosToShow.length - 1) { // from last slide back to the first slide
      setCurrentVideo(0);
    } else if (linkIndex === 1 && currentVideo !== videosToShow.length - 1) { // regular right slide
      setCurrentVideo(currentVideo + linkIndex);
    } else if (linkIndex === -1 && currentVideo === 0) { // from first slide to the last slide
      setCurrentVideo(videosToShow?.length - 1);
    } else if (linkIndex === -1 && currentVideo !== 0) { // regular left slide
      setCurrentVideo(currentVideo + linkIndex);
    }
  };

  const handleMenuSlideChange = (i: number) => setCurrentVideo(i);

  return (
    <Box
      backgroundColor="solidWhite"
      mx={{ _: "0", sm: "1%" }}
      {...swipeHandlers}
    >
      <Box py={{ sm: "5rem" }} overflowX="hidden" id={"#" + stringify(detailPage.values.anchorKeyFeatures)}>
        <Box id={stringify(detailPage.values.anchorKeyFeatures)} transform={{ _: "translateY(-6rem)", md: "translateY(-13.9rem)" }} />
        <Flex flexDirection="column" alignItems={{ xl: "center" }} paddingX={{ _: 0, xs: "16px" }}>
          <RobeProductDetailHeading disableCollapsable>
            <InlineValue value={detailPage.values.keyFeaturesTitle ?? "Key Features"} />
          </RobeProductDetailHeading>

          <Flex py={{ xs: "1rem", sm: "3rem" }} alignItems="center" justifyContent="center" width={{ _: "100%", xl: "65%" }}>
            {/* landscape mode left carousel arrow */}
            {videosToShow.length > 1 && isLandscapeMode && (
              <Link
                p={4}
                _hover={{ transform: "scale(1.2)" }}
                transition="300ms"
                position="relative"
                top={{ _: "-5rem", md: "5rem" }}
                onClick={() => handleArrowSlideChange(-1)}
              >
                <Icon
                  width="3.2rem"
                  svg={<ArrowIcon />}
                  fill="robe"
                  transform="rotate(90deg)"
                />
              </Link>
            )}
            <Flex
              flexDirection="column"
              justifyContent="center"
              width={isLandscapeMode ? { _: "77%", xl: "100%" } : "100%"}
              {...(videosToShow.length <= 1 && isLandscapeMode && { mx: "10rem" })}
            >
              <Flex
                justifyContent={{ md: "space-between" }}
                alignItems={{ md: "center" }}
                overflowX={{ _: "hidden" }}
              >
                <Flex
                  width={{ _: "100%" }}
                  flexDirection="row"
                  marginBottom={{ _: "1rem", xs: "3rem" }}
                  style={{ scrollBehavior: "smooth" }}
                  transition="all 0.3s ease-in-out"
                  transform={`translateX(${(screenWidth - slidesContainerPadding - currentFeatureItemWidth - scrollbarWidth) / 2 - totalPreviousFeatureItemsWidth}px)`}
                >
                  {videosToShow.map((v, i) => {
                    const title = stringify(v.values.title);
                    return (
                      <FeatureItem
                        id={`feature-item-${i}`}
                        key={value(v.id)}
                        onClick={() => handleMenuSlideChange(i)}
                        title={title === "" || typeof title === undefined ? "Untitled" : title!}
                        selected={currentVideo === i}
                        setCurrentFeatureItemWidth={setCurrentFeatureItemWidth}
                      />
                    );
                  })}
                </Flex>
              </Flex>

              {videosToShow.length > 1 && (
                <MobileVideoWrapper
                  display={isLandscapeMode ? "none" : "flex"} // show only for portrait mode
                  position="relative"
                  style={{ zIndex: 11 + videosToShow.length }}
                  mt={{ _: "1rem", md: "3rem" }}
                >
                  {/* portrait mode left carousel arrow */}
                  <Box
                    color="red"
                    position="absolute"
                    top="0"
                    py="0.5rem"
                    mt={`${(carouselRef?.current?.clientHeight ?? 0 / 2) - 25}px`}
                    style={{ zIndex: 12 + videosToShow.length }}
                  >
                    <Link
                      p={0}
                      _hover={{ transform: { _: "", md: "scale(1.2)" } }}
                      transition="300ms"
                      animation="pulse-left 2s infinite"
                      onClick={() => handleArrowSlideChange(-1)}
                    >
                      <Icon
                        width="3.2rem"
                        svg={<ArrowIcon />}
                        fill="#fff"
                        transform="rotate(90deg)"
                        display="flex"
                        height="2rem"
                        ml="0.6rem"
                      />
                    </Link>
                  </Box>
                  {/* portrait mode right carousel arrow */}
                  <Box
                    position="absolute"
                    top="0"
                    right="0"
                    py="0.5rem"
                    mt={`${(carouselRef?.current?.clientHeight ?? 0 / 2) - 25}px`}
                    style={{ zIndex: 12 + videosToShow.length }}
                  >
                    <Link
                      p={0}
                      _hover={{ transform: { _: "", md: "scale(1.2)" } }}
                      transition="300ms"
                      animation="pulse-right 2s infinite"
                      onClick={() => handleArrowSlideChange(1)}
                    >
                      <Icon
                        width="3.2rem"
                        svg={<ArrowIcon />}
                        fill="#fff"
                        transform="rotate(270deg)"
                        display="flex"
                        height="2rem"
                        mr="0.6rem"
                      />
                    </Link>
                  </Box>
                </MobileVideoWrapper>
              )}

              <Box position="relative" ref={slidesContainerRef}>
                <Flex ref={carouselRef}>
                  <VideoCarousel
                    width="100%"
                    height="100%"
                    videos={[videosToShow[currentVideo]]}
                    keyFeaturesCarousel
                    // setLoadedVideosIds={setLoadedVideosIds} // *thumbnail rendering feature*
                    // renderThumbnailsExternally // *thumbnail rendering feature*
                  />

                  {/* The feature below loads video thumbnails faster, but the frame weirdly jumps when the thumbnail is being replaced by video itself.
                      If performance of thumbnail loading is too slow, this feature should be uncommented and the jumping between thumbnail and video somehow resolved.
                  */}

                  {/* *thumbnail rendering feature* prerender thumbnails on initial page load, and hide them with opacity */}
                  {/* {videosToShow.map((v, i) => {
                    const hideThumbnail = currentVideo === i ? loadedVideosIds.includes(v.id) : true;

                    // Do not prerender thumbnails for vimeo videos
                    if (v.type === "video") {
                      return <></>;
                    }

                    return (
                      <Img
                        key={v.id}
                        width="100%"
                        height="100%"
                        src={file(v.values.videoThumbnail, "740x416")}
                        alt={stringify(v.values.internalDes)}
                        style={{ position: "absolute", zIndex: 10 + videosToShow.length - i, opacity: hideThumbnail ? 0 : 1 }}
                        loading="eager"
                      />
                    );
                  })} */}
                </Flex>
                <Box
                  position={{ _: "static", md: "absolute" }}
                  bottom="0"
                  pt={{ _: "3rem", md: "0.9rem" }}
                  px={{ _: "0", md: "3rem" }}
                  width="100%"
                  pb="2rem"
                  pointerEvents="none"
                  backgroundColor={{
                    _: "none",
                    md: videosToShow[currentVideo]?.values.darkBackground
                      ? "rgba(0, 0, 0, 0.7)"
                      : "none"
                  }}
                >
                  <RobeRichtext
                    value={localize(videosToShow[currentVideo]?.values.description)}
                    _paragraph={{
                      variant: "lead",
                      textAlign: "center",
                      color: { _: "#000", md: "#fff" } as any,
                      fontSize: "19px",
                      lineHeight: "2.8rem",
                      userSelect: "none",
                      textShadow: { _: "none", md: "1px 1px #000000" },
                      mx: "2rem"
                    }}
                    _link={{
                      fontSize: "inherit",
                      lineHeight: "inherit"
                    }}
                  />
                </Box>
              </Box>
            </Flex>
            {/* landscape mode right carousel arrow */}
            {videosToShow.length > 1 && isLandscapeMode && (
              <Box position="relative" top={{ _: "-5rem", md: "5rem" }}>
                <Link p={4} _hover={{ transform: "scale(1.2)" }} transition="300ms" onClick={() => handleArrowSlideChange(1)}>
                  <Icon
                    width="3.2rem"
                    svg={<ArrowIcon />}
                    fill="robe"
                    transform="rotate(-90deg)"
                  />
                </Link>
              </Box>
            )}
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default ProductKeyFeatures;

type FeatureItemProps = {
  id: string;
  title: string;
  onClick: () => void;
  selected: boolean;
  setCurrentFeatureItemWidth: Dispatch<SetStateAction<number>>;
};

const FeatureItem: FC<FeatureItemProps> = ({ onClick, title, id, selected, setCurrentFeatureItemWidth }) => {
  const ref = useRef<HTMLDivElement>(null);

  // set current feature item width. Because it accesses the DOM, this computation must be here to run after the initial render
  useEffect(() => {
    if (selected && ref.current) {
      setCurrentFeatureItemWidth(ref.current.getBoundingClientRect().width);
    }
  }, [selected, setCurrentFeatureItemWidth]);

  return (
    <Box
      id={id}
      pb="1rem"
      px={{ _: "1rem", xs: "2rem" }}
      textAlign="center"
      borderBottom="3px solid"
      borderColor={selected ? "robe" : "transparent"}
      cursor="pointer"
      onClick={onClick}
      ref={ref}
    >
      <Link
        fontWeight={selected ? 600 : "normal"}
        fontSize={{ _: "1.3rem", md: "2rem" }}
        color="black"
        _hover={{ color: "black" }}
        lineHeight={{ _: "1.8rem", md: "2.5rem" }}
        style={{ minWidth: "7rem", whiteSpace: "nowrap" }}
        display="flex"
        justifyContent="center"
      >
        {title}
      </Link>
    </Box>
  );
};

const MobileVideoWrapper = styled(Box)`
  @keyframes pulse-right {
    0% {
      transform: translateX(0rem);
    }

    50% {
      transform: translateX(0.5rem);
    }

    100% {
      transform: translateX(0rem);
    }
  }

  @keyframes pulse-left {
    0% {
      transform: translateX(0rem);
    }

    50% {
      transform: translateX(-0.5rem);
    }

    100% {
      transform: translateX(0rem);
    }
  }
`;
